#navbar-main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    transition: $transition-slow;

    .nav-item {
        margin-left: 1em;
    }

    .search-form {
        width: 45%;
        margin: 0 auto;
        display: flex;

        .input-group {
            border-radius: $input-border-radius;
            filter: drop-shadow(0 0.125rem 0.25rem rgba(0, 0, 0, 0.075));
        }

        .input-group-text {
            background-color: $body-bg-gray;
        }
    }

    @media (max-width: 1400px) {
        #nav-main-menu {
            max-width: calc(100% - 500px);
        }
    }

    .nav-item .nav-link.btn-secondary {
        color: #fff;
    }

    .navbar-brand {
        .img-fluid {
            max-height: 40px;
            object-position: left;
            object-fit: contain;
        }

        @media (max-width: 768px) {
            max-width: calc(100% - 145px);
        }
    }
}

body.rtl {
    #navbar-main {
        .navbar-brand {
            .img-fluid {
                object-position: right;
            }
        }

        .btn-group {
            flex-direction: row-reverse;
        }
    }
}

.header-gray {
    .header {
        background-color: $body-bg-gray;
    }

    #navbar-main .search-form {
        .form-control,
        .input-group-text {
            background-color: $body-bg;
        }
    }
}

.header-home {
    #navbar-main {
        .search-form {
            display: none;
            opacity: 0;
        }
    }

    @media (max-width: 768px) {
        .py-4 {
            padding-top: 0px !important;
            z-index: 10;
            background-color: #fff;
            padding-bottom: 40px !important;
        }
    }
}

.is-scrolled {
    #navbar-main {
        background-color: $body-bg-gray;
        border-bottom: 1px solid #e2e2e2;

        .search-form {
            display: flex;
            opacity: 1;
            transition: $transition-slow;

            .form-control,
            .input-group-text {
                background-color: $body-bg;
            }

            @media (max-width: 768px) {
                display: none;
            }
        }
    }
}

.navbar-collapse {
    position: fixed;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background: $primary;
    top: 0px;
    z-index: 1500;

    a.nav-link {
        font-size: 28px;
        color: $light !important;
        font-weight: 600;
        padding: 2px;
    }

    .navbar-toggler {
        text-align: left;
        border-color: none;
        color: $secondary;
    }

    .menu-close {
        font-weight: 600;
        cursor: pointer;
        font-size: 18px;
    }

    @media (max-width: 768px), (max-height: 768px) {
        overflow-y: auto;
    }
}

.navbar-toggler.btn-primary {
    background-color: $primary;
    color: #fff;
    padding: 12px 30px;

    &:hover {
        background-color: lighten($primary, 5%);
    }
}

body #navbar-full .search-form {
    width: 100%;
    display: flex !important;
    opacity: 1 !important;
}

#offcanvas-locale {
    min-height: 450px;
    max-height: 100%;
    background-color: $primary;

    @media (max-width: 768px) {
        min-height: 650px;
        max-height: 85vh;
    }

    .nav-link {
        color: #fff;
    }

    .menu-close {
        font-weight: 600;
        cursor: pointer;
        font-size: 18px;
    }

    &.offcanvas-top {
        height: auto;
    }
}
