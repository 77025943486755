// Variables
@import "variables";

// Utilities
@import "utilities";

// comments
@import "comments";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

@font-face {
    font-family: "sofia-pro";
    src: url("https://use.typekit.net/af/09d302/00000000000000007735a14e/30/l?primer=da99699d234c77da574e724488197e81ab3504bcbff44b9ef82b3d9b3491d804&fvd=n7&v=3") format("woff2"),
        url("https://use.typekit.net/af/09d302/00000000000000007735a14e/30/d?primer=da99699d234c77da574e724488197e81ab3504bcbff44b9ef82b3d9b3491d804&fvd=n7&v=3") format("woff"),
        url("https://use.typekit.net/af/09d302/00000000000000007735a14e/30/a?primer=da99699d234c77da574e724488197e81ab3504bcbff44b9ef82b3d9b3491d804&fvd=n7&v=3") format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: "sofia-pro";
    src: url("https://use.typekit.net/af/5e6988/00000000000000007735a163/30/l?primer=da99699d234c77da574e724488197e81ab3504bcbff44b9ef82b3d9b3491d804&fvd=n4&v=3") format("woff2"),
        url("https://use.typekit.net/af/5e6988/00000000000000007735a163/30/d?primer=da99699d234c77da574e724488197e81ab3504bcbff44b9ef82b3d9b3491d804&fvd=n4&v=3") format("woff"),
        url("https://use.typekit.net/af/5e6988/00000000000000007735a163/30/a?primer=da99699d234c77da574e724488197e81ab3504bcbff44b9ef82b3d9b3491d804&fvd=n4&v=3") format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: 400;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body.rtl {
    direction: rtl;

    #home-header.header:before,
    #page-header-img.header:before {
        left: 0;
        right: auto;
        transform: scaleX(-1);
    }
}

a {
    text-decoration: none;
}

p {
    font-size: 16px;
    line-height: 26px;
    color: #89768f;
    font-weight: 500;

    @media (max-width: 768px) {
        font-size: 15px;
    }
}

h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
    color: $primary;
}

h1 {
    font-size: 60px;
    line-height: 68px;
    font-weight: bold;

    @media (max-width: 768px) {
        font-size: 40px;
        line-height: 46px;
    }
}

ul li {
    font-size: 16px;
}

h2,
.h2 {
    font-size: 42px;
    line-height: 48px;
    font-weight: 600;

    &.max-600 {
        max-width: 600px;
    }

    @media (max-width: 768px) {
        font-size: 30px;
        line-height: 34px;
    }
}

h3,
.h3 {
    font-size: 22px;
}

.cursor-pointer {
    cursor: pointer;
}

.btn-primary {
    &:hover {
        background-color: lighten($primary, 6%);
        border-color: lighten($primary, 3%);
    }
}

.btn-secondary {
    color: $light;

    &:hover {
        color: $light;
        background-color: #c15852;
        border-color: #c15852;
    }
}

.border-bottom-red {
    border-bottom: 3px solid #e23434;
    padding-bottom: 8px;
    margin-left: 12px;
    margin-right: -15px;
    font-size: 18px;
    z-index: 10;
    font-weight: bold;

    @media (max-width: 768px) {
        padding-bottom: 8px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 18px;
        z-index: 10;
        text-align: left !important;
        font-weight: bold;
    }
}

.border-bottom-gray {
    border-bottom: 1px solid #e2e2e2;
    margin-right: 12px;
    margin-left: -15px;

    @media (max-width: 768px) {
        margin-left: 0px;
        margin-right: 0px;
    }
}

.border-selected-review {
    border: 2px solid $secondary;
}

.btn-success {
    color: $light;

    &:hover,
    &:active:focus {
        color: $light;
    }
}

.btn-report-review {
    color: #89768f;
    font-weight: 500;
    font-size: 14px;
}

.form-control:focus {
    color: #6e5574;
    background-color: #f7f8fa;
    border-color: #98879d;
    outline: 0;
    box-shadow: 0 0 0 0.1rem rgb(49 14 58 / 6%);
}

.bg-primary>p,
.bg-primary h2,
.bg-primary h3,
.bg-primary h4 {
    color: $light;
}

.bg-pattern {
    background: url("/img/bg-pattern.png");
    background-size: cover;
}

.pull-top {
    margin-top: -5vh;

    @media (max-width: 767px) {
        margin-top: 0px;
    }
}

.header {
    padding-top: 8em;
    padding-bottom: 2em;

    @media (max-width: 768px) {
        padding-top: 6em;
    }
}

#home-header.header {
    position: relative;
    padding-bottom: 0px;
    min-height: 80vh;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: calc(50% - 1rem);
        height: 100%;
        background-image: url("/img/heading-home.jpg");
        background-repeat: no-repeat;
        background-position: top left;
        border-bottom-left-radius: 5rem;
        background-size: cover;
        z-index: 1;

        @media (max-width: 767px) {
            bottom: 0;
            width: calc(100% - 70px);
            height: 30%;
            background-position: bottom right;
            border-top-left-radius: 5rem;
            border-bottom-left-radius: 0rem;
            top: auto;
        }

        @media (max-width: 768px) and (max-height: 720px) {
            display: none;
        }
    }

    span {
        font-weight: 700;
        font-size: 16px;
    }

    p {
        font-size: 22px;
        line-height: 30px;

        @media (max-width: 768px) {
            font-size: 16px;
        }
    }

    .input-group {
        box-shadow: $box-shadow-lg;
        border-radius: $border-radius;

        .input-group-text {
            background-color: #fff;
            color: #c4bac7;

            &:direction(ltr) {
                padding-right: 0;
            }

            &:direction(rtl) {
                padding-left: 0;
            }
        }
    }

    .form-control {
        padding: 1.5rem;
        line-height: 1.6;
        color: $body-color;
        background-color: #fff;
        border: 0 solid #ced4da;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        box-shadow: none;
    }

    @media (max-width: 768px) {
        min-height: 100vh;

        @media (max-width: 768px) and (max-height: 720px) {
            min-height: 85vh;
        }

        .home-usp {
            .icon-usp {
                font-size: 28px;
            }

            .text-usp {
                font-size: 12px;
                line-height: 14px;
            }
        }
    }
}

.sticky-scroller {
    font-size: 18px;
    top: 30px;
    margin-top: -254px;
    z-index: 1;
    margin-bottom: 30px;
    border-radius: 0.4rem;
    box-shadow: 0 2px 60px 0 rgb(0 0 0 / 4%);

    .img-company-thumb {
        border: 1px solid#E2E2E2;
    }

    .btn {
        font-size: 14px;
        font-weight: bold;
    }

    .cat-wrapper {
        line-height: 20px;
    }

    .comp-cat {
        font-size: 14px;
        color: #b7abba;
        padding-bottom: 2px;
    }

    .comp-cat-detail,
    .comp-website-detail {
        display: block;
    }

    .btn-visit-website:hover {
        cursor: pointer;
    }
}

// #page-header-img {
//     position: relative;
//     min-height: 80vh;

//     &::before {
//         content: '';
//         position: absolute;
//         top: 80px;
//         right: 0;
//         width: calc(50% - 1rem);
//         height: calc(90% - 1rem);
//         background-image: url('/img/heading-about.png');
//         background-repeat: no-repeat;
//         background-position: left right;
//         background-size: cover;
//         z-index: 1;
//     }

//     @media (max-width: 767px) {
//         bottom: 0;
//         width: calc(100% - 70px);
//         height: 30%;
//         background-position: right left;
//         border-top-left-radius: 5rem;
//         border-bottom-left-radius: 0rem;
//         top: auto;
//     }

//     @media (max-width: 768px) and (max-height: 720px) {
//         display: none;
//     }
// }

#page-header-img.header {
    position: relative;
    padding-bottom: 0px;
    min-height: 80vh;

    &::before {
        content: "";
        position: absolute;
        top: 80px;
        right: 0;
        width: calc(50% - 1rem);
        height: calc(90% - 1rem);
        height: 100%;
        background-image: url("/img/heading-about.png");
        background-repeat: no-repeat;
        background-position: left right;
        background-size: cover;
        z-index: 1;

        @media (max-width: 767px) {
            bottom: 0;
            width: calc(100% - 70px);
            height: 30%;
            background-position: bottom right;
            border-top-left-radius: 5rem;
            border-bottom-left-radius: 0rem;
            top: auto;
        }

        @media (max-width: 768px) and (max-height: 720px) {
            display: none;
        }
    }

    span {
        font-weight: 700;
        font-size: 16px;
    }

    p {
        font-size: 22px;
        line-height: 30px;

        @media (max-width: 768px) {
            font-size: 16px;
        }
    }

    .input-group {
        box-shadow: $box-shadow-lg;
        border-radius: $border-radius;

        .input-group-text {
            background-color: #fff;
            color: #c4bac7;
            padding-right: 0;
        }
    }

    .form-control {
        padding: 1.5rem;
        line-height: 1.6;
        color: $body-color;
        background-color: #fff;
        border: 0 solid #ced4da;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        box-shadow: none;
    }

    @media (max-width: 768px) {
        min-height: 100vh;

        @media (max-width: 768px) and (max-height: 720px) {
            min-height: 85vh;
        }
    }
}

#page-header-img.header-contact {
    &::before {
        background-image: url("/img/heading-contact.png");
    }

    @media (max-width: 767px) {
        width: 100%;
    }

    @media (max-width: 768px) and (max-height: 720px) {
        display: block;

        &::before {
            display: none;
        }
    }
}

.cat-list {
    height: 120px;
    overflow: scroll;
    margin-bottom: 30px;
}

.frills {
    &::before {
        content: "";
        position: absolute;
        background: url(/img/frills-1.png);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        top: -30px;
        width: 130px;
        left: 10vw;
        height: 70px;

        @media (max-width: 767px) {
            z-index: 0;
            height: 50px;
            background-size: contain;
        }
    }

    &::after {
        content: "";
        position: absolute;
        background: url(/img/frills-2.png);
        width: 100px;
        height: 130px;
        bottom: -50px;
        right: 20vw;

        @media (max-width: 768px) {
            width: 60px;
            height: 90px;
            bottom: -50px;
            right: 20vw;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
}

.cat-card {
    transition: $transition-base;
    color: #f8f9fa;
    background-color: rgba(248, 249, 250, 0.1);
    border-radius: 5px;
    padding: 30px 25px;
    height: 100%;

    i {
        vertical-align: middle;
        font-size: 26px;
    }

    h3,
    .h3 {
        padding: 0px;
        margin: 0px;
        font-size: 20px;
    }

    &.bg-light h3 {
        color: $primary;
    }

    &:hover {
        color: $primary !important;
        background-color: #fff;

        h3,
        .h3 {
            color: $primary !important;
        }
    }
}

.category-cards {

    h3,
    .h3 {
        display: block;
    }

    @media (max-width: 768px) {
        text-align: center;

        h3,
        .h3 {
            font-size: 20px !important;
        }

        img {
            height: 60px;
            width: 60px;
        }

        .img-company-thumb {
            padding: 0px;
        }
    }
}

.company-score {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .stars {
        display: flex;
        align-items: center;
        flex-direction: column-reverse;

        .stars-block {
            font-size: 26px;
        }

        .stars-score {
            font-size: 36px;
            font-weight: 600;
        }
    }
}

.company-rating-shadow {
    box-shadow: 0 2px 60px 0 rgba(0, 0, 0, 0.04);
}

.company-divider {
    border-bottom: 1px solid #cecece;
}

.connected-companies {
    .card {
        background: transparent;

        i {
            font-size: 26px;
        }

        h3,
        .h3 {
            padding: 0px;
            margin: 0px;
            font-size: 20px;
        }

        .row .col {
            width: 100%;
            display: block;
            border-bottom: 2px solid rgba(0, 0, 0, 0.1);
            padding: 20px 0px;
            color: $light;
        }

        ul {
            padding-right: 0;
        }

        ul li:last-child a {
            border-bottom: none;
        }

        .list-group-item {
            background-color: rgba(255, 255, 255, 0.1);
            border: 0px;
            margin: 0px;
            padding: 0px 30px;
        }

        .card-header span {
            vertical-align: middle;
        }
    }

    .card-header {
        background-color: rgba(248, 249, 250, 0.2);
        padding: 25px;
    }
}

.tooltip {
    margin-bottom: 4px !important;

    .tooltip-inner {
        font-weight: bold !important;
        font-size: 16px !important;
        width: 100%;
        max-width: 100%;
        padding: 6px 15px;
    }
}

.bg-gray {
    background-color: $body-bg-gray !important;
}

.bg-body-color {
    background-color: rgba(250, 250, 250, 0.15) !important;
}

.text-light-purple {
    &::placeholder {
        color: #6e5574 !important;
    }

    &:focus {
        color: #fff;
    }
}

.text-light-gray {
    color: #bbbbbb;
}

.text-blue {
    color: #0000a5;
}

.img-thumbnail {
    border-width: 0;
    border-radius: $border-radius;
    background-color: $body-bg-gray;
    padding: 0;
}

.logo-container {
    background: url("/img/logo-bg.png");
    height: 500px;
    position: relative;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;

    .logo-item {
        position: absolute;
        filter: drop-shadow(0px 3px 60px rgba(0, 0, 0, 0.1));

        img {
            transition: $transition-base;
        }
    }

    a.logo-item:hover img {
        transform: scale(1.1);
    }
}

.cta {
    p {
        margin: 0px;
    }

    .rounded {
        @media (max-width: 768px) {
            border-radius: 0px !important;
        }
    }
}

.faq {
    .accordion-button {
        font-size: inherit;
        color: inherit;
    }
}

.readmore {
    i {
        vertical-align: middle;
        transition: $transition-base;
    }

    &:hover i {
        -webkit-transform: translateY(4px);
        -moz-transform: translateY(4px);
        -ms-transform: translateY(4px);
        -o-transform: translateY(4px);
        transform: translateY(4px);
    }

    span {
        vertical-align: middle;
    }
}

.accordion-button:focus {
    box-shadow: none;
}

.accordion-button:not(.collapsed) {
    background-color: rgb(255, 255, 255, 0.4);
}

.breadcrumb {
    margin-bottom: 0rem;

    .breadcrumb-item>span {
        color: $link-color;
    }

    .breadcrumb-item {
        opacity: 0.4;
    }

    .breadcrumb-item.active {
        opacity: 1;
    }
}

.modal-backdrop.show {
    opacity: 0.9;
}

@keyframes float {
    0% {
        transform: translateY(10px);
    }

    50% {
        transform: translateY(0px);
    }

    100% {
        transform: translateY(10px);
    }
}

@keyframes float1 {
    0% {
        transform: translateY(-5px);
    }

    50% {
        transform: translateY(0px);
    }

    100% {
        transform: translateY(-5px);
    }
}

.float-small {
    -webkit-animation: float 10s ease-in-out infinite;
    animation: float 15s ease-in-out infinite;
}

.float-smaller {
    -webkit-animation: float1 10s ease-in-out infinite;
    animation: float1 10s ease-in-out infinite;
}

// Pagination
.pagination .page-item .page-link {
    border-radius: $border-radius;
    margin-right: 5px;
    min-width: 2.7em;
    text-align: center;
}

// Swirl
.card.swirls::before {
    position: absolute;
    display: block;
    background-image: url("/img/swirl_top_left.svg");
    content: "";
    width: 93px;
    height: 38px;
    top: -12.4px;
    left: -20px;
}

.card.swirls::after {
    position: absolute;
    display: block;
    background-image: url("/img/swirl_bottom_right.svg");
    content: "";
    width: 139px;
    height: 37px;
    bottom: -19px;
    right: 150px;
    z-index: 1;
}

.search-form {
    position: relative;

    .search-results {
        z-index: 5;
        position: absolute;
        top: 100%;
        top: calc(100% - 2px);
        left: 0;
        width: 100%;
        box-shadow: $box-shadow;
        background-color: $light;
        padding: 0.5rem;
        border-radius: 0 0 $border-radius $border-radius;
    }
}

.blog-content img {
    width: 100%;
    height: auto;
    border-radius: 0.4rem;
}

.blog-detail {

    .scroll-margin,
    .blog-section {
        scroll-margin-top: 100px;
    }

    .blog-section {
        margin-top: 2rem;
    }

    .blog-section-type-related {
        margin-top: 3rem;
    }

    .blog-section-type-star {
        overflow-x: auto;
    }

    .blog-items {
        object-fit: cover;
        height: 400px;
    }

    ol,
    ul {
        padding-left: 1rem;
        margin-bottom: 0rem;
    }

    li {
        padding-bottom: 4px;
    }

    .blog-cat-img {
        border-radius: 100%;
        background-color: #fff;
        padding: 0.5rem;
        -o-object-fit: contain;
        object-fit: contain;
        -o-object-position: center;
        object-position: center;
        max-width: 100%;
    }

    .blog-title {
        font-size: 18px;
        font-weight: bold;
        vertical-align: middle;
    }

    .alert {
        border: 0px;
        border-left: 5px solid;
    }

    .blog-product-list .btn {
        padding: 8px 20px;
    }

    .alert-danger {
        border-color: #ff6056;
        background-color: #ffefee;
        padding: 25px 30px;

        p {
            color: #ff6056;
            font-size: 16px;
            line-height: 22px;
        }

        .h3 {
            font-weight: bold;
            color: #ff6056;
            font-size: 22px;
        }
    }
}

.blog-large,
.blog-small {
    .img-article {
        object-fit: cover;
    }
}

.article-list {
    line-height: 16px;
    padding: 8px 0px;
    font-size: 15px;
}

.stars-mobile {
    @media (max-width: 768px) {
        overflow-x: scroll;
    }

    width: 890px;
}

.post-review-date,
.post-review-name {
    opacity: 0.6;
}

.post-review-date {
    margin-right: 10px;
}

.total-buyagain-percentage {
    border-top: 1px solid rgba(255, 255, 255, 0.35);
    padding-top: 12px;

    span {
        opacity: 0.65;
    }

    .percentage {
        opacity: 1;
        font-weight: 600;
    }
}

.review-buyagain {
    color: #b7aaba;
}

.review-buyagain .buyagain-yes,
.review-buyagain .buyagain-no {
    color: #fff;
    font-size: 16px;
    line-height: 16px;
    border-radius: 4px;
    margin-left: 8px;
    display: inline-block;
    font-weight: 600;
    padding: 3px 8px 9px 8px !important;
}

.review-buyagain .buyagain-yes {
    background-color: $success;
}

.review-buyagain .buyagain-no {
    background-color: #ff5f56;
}

.review-score {
    font-weight: 500;
    color: #b7aaba;
    font-size: 16px;
}

.add-review-hashtag hr {
    margin: 1rem 0;
}

.add-review {
    h2 {
        font-weight: bold;
    }

    padding: 1.25rem;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 60px 0 rgb(0 0 0 / 4%);

    .form-control,
    .form-select {
        background-color: #f6f7f9 !important;
        border: 1px solid #e2e2e2;
        padding: 16px;
    }
}

.card-review .card-body {
    box-shadow: 0 2px 60px 0 rgba(0, 0, 0, 0.04);
}

.card-company-small {
    background-color: transparent;
    border: 1px solid #e2e2e2;
}

.new-company {
    display: block;
    padding: 0.625rem;
    background-color: rgba(248, 249, 250, 0.1);
    border-radius: 0.25rem;
    text-align: center;
    color: #eee;
    font-weight: bold;
    transition: $transition-base;

    &:hover {
        background-color: rgba(248, 249, 250, 0.2);
        color: #fff;
    }
}

#faq {
    a {
        text-decoration: underline;
        color: inherit;
    }

    b {
        color: #310e3a;
    }

    span.btn-visit-website {
        &:hover {
            cursor: pointer;
        }
    }
}

.business-alert {
    width: auto !important;
    z-index: 20;
}

.hide-box-shadow {
    box-shadow: none !important;
}

@import "importables";
