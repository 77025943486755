// Body
$body-bg: #fff;
$body-bg-gray: #F7F8FA;
$body-color: #6E5574;

$font-family-sans-serif: 'sofia-pro', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace: 'sofia-pro', SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

$font-size-base: 0.9rem;
$line-height-base: 1.6;

$border-radius: .4rem;
$border-radius-sm: .2rem;
$border-radius-lg: .6rem;

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1370px
);

$spacer: 2.5rem;

$primary: #310E3A;
$secondary: #FF5F56;
$success: #19B353;

$input-btn-padding-y: 12px;
$input-btn-padding-x: 30px;
$input-padding-y: $input-btn-padding-y*1.3;
$input-padding-x: $input-btn-padding-x;
$btn-font-size: 16px;
$btn-border-radius: 3rem;

$btn-padding-y-lg: $input-btn-padding-y * 1.3;
$btn-padding-x-lg: $input-btn-padding-x * 1.3;
$btn-font-size-lg: $btn-font-size * 1.3;
$btn-border-radius-lg: $btn-border-radius * 1.3;

$input-border-width: 0;
$input-bg: $body-bg-gray;

$transition-slow: all .4s ease-in-out;

$breadcrumb-divider: quote(">");

$progress-height: 1em;

$headings-margin-bottom: calc($spacer / 4);

// Pagination

$pagination-color: #adb5bd;
$pagination-active-bg: $secondary;
$pagination-padding-y: .425rem;

$tooltip-max-width: 200px;
$tooltip-bg: $primary;
$tooltip-border-radius: $border-radius;
$tooltip-opacity: 1;

@import "custom-variables";
