.comment-border {
    position: relative;
    border: 1px solid #E2E2E2;
    border-radius: 0.4rem;
    padding: 14px;

    &.cb-left::before,
    &.cb-right::before {
        content: "";
        position: absolute;
        display: inline-block;
        width: 18px;
        height: 18px;
        top: 11px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center right;
        background-image: url("/img/commentbox-decoration.svg");
    }

    &.cb-left::before {
        right: 100%;
    }

    &.cb-right::before {
        left: 100%;
        transform: scaleX(-1);
    }
}

@media (max-width: 767px) {
    .mobile-answer-margin {
        margin-top: 20px;
    }
    
    .flex-direction-column {
        flex-direction: column;
        align-items: flex-start!important;
    }

    .mobile-w-100 {
        width: 100%;
    }
}