footer{
    background-color: $body-bg;
    border-top: 1px solid rgba($primary, 0.1);
    padding: 5rem 0 3rem 0;

    .nav-link{
        padding-left: 0;
    }

    #copyright{
        margin-top: 5rem;
        padding-bottom: 0;
        font-size: 1rem;
        text-align: center;
    }

    h4 {
        font-weight: 600;
    }

    .social-footer{
        font-size:30px;
        i{
            color:$primary;
            transition:$transition-base;
        &:hover{
            color:$secondary;
        }
    }
    }
}
