@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

$primary: #138ca2;
$secondary: #5eaf00;
$success: #34ac52;

$font-family-sans-serif:
    "Ubuntu",
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji";
$font-family-monospace: "Ubuntu", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

$border-radius: 0.25rem;

.input-group {
    border-radius: 6rem !important;

    .input-group-text {
        border-radius: 6rem 0 0 6rem !important;
    }

    input[type="search"] {
        border-radius: 0 6rem 6rem 0 !important;
    }
}

.review-icon {
    color: $primary !important;
}

button,
.btn {
    border-radius: $border-radius !important;
}

.category-cards {
    color: #138ca2 !important;
}

.bg-pattern {
    background: #f7f8fa !important;
}

.post-review-date,
.post-review-name,
.text-muted,
.card-company-small {
    color: rgba(0, 0, 0, 0.8) !important;
}

#home-header.header {
    &::before {
        background-position: bottom !important;
        background-image: url("/img/header-home-webwinkelwijzer.jpg") !important;
    }
}

#page-header-img.header::before {
    background-image: url("/img/heading-about-webwinkelwijzer.jpg") !important;
    background-position: center center;
    border-radius: 4rem 0 0 0;
    background-size: 100% 95rem;
}

.btn-secondary:hover {
    background-color: lighten($secondary, 5) !important;
    border-color: lighten($secondary, 5) !important;
}

.page-link.active,
.active > .page-link {
    background-color: $primary !important;
    border-color: $primary !important;
}

.border-bottom-red {
    border-bottom: 3px solid darken($primary, 10) !important;
}
