.card-header,
.card-body,
.card-footer {
    background-color: transparent;
    padding: calc($spacer / 2);
}

.card-title {
    margin-bottom: 0;
}
